import React from 'react'
import styled from 'styled-components'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUsePtag } from './ReUsePtag'
import { SignUpButton } from './SignUpButton'
import SCOLogoData from '../Data/SCOLogoData.json'
import { LinkHubspot } from './LinkHubspot'
import { AppSOCReport } from './AppSOCReport'

const AppSCOLogoWepper = styled.div`
  max-width: 1920px;
  margin: auto;
  .mainSection {
    padding: 80px 96px 80px 96px;
    @media (max-width: 1080px) {
      padding: 0px 15px 80px 15px;
    }
  }
  .ParSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
  }
  .SCOLogo {
    height: auto;
    width: 85%;
    @media (max-width: 1080px) {
      display: flex;
      margin: auto;
      height: auto;
      width: 100%;
    }
  }
  .SCOBannerparaOne {
    padding-top: 3%;
    max-width: 900px;
  }
  .SCOBannerpara {
    padding-top: 1%;
    max-width: 900px;
  }
  .hyperColorLink {
    color: #34a1d5;
    text-decoration: none;
  }
  .ButtonSection {
    padding-top: 5%;
  }
  .GetTryFreeButton {
    text-align: center;
    margin-top: 100px;
  }
  .SingUPButton {
    justify-content: center;
    display: flex;
    padding-top: 2%;
  }
`

export const AppSCOLogo = () => {
  return (
    <AppSCOLogoWepper>
      {SCOLogoData.map((data, index) => {
        return (
          <div key={index}>
            <div className="mainSection">
              <div className="ParSection">
                <div>
                  <ReuseImgTag ImageStyle="SCOLogo" ImagePath={data.ImagePath} AltName={data.AltName} />
                </div>
                <div>
                  <ReUsePtag para={data.paraOne} paraStyle="SCOBannerparaOne" />
                  <p className="SCOBannerpara">
                    {data.paraTwo}{' '}
                    <LinkHubspot to="/legal" className="hyperColorLink">
                      {data.LegalPara}
                    </LinkHubspot>{' '}
                    {data.paraTwoLast}
                  </p>
                  <ReUsePtag para={data.paraThree} paraStyle="SCOBannerpara" />
                  <ReUsePtag para={data.paraFour} paraStyle="SCOBannerpara" />
                  <p className="SCOBannerpara">
                    {data.paraFive}
                    <a
                      href="mailto:security@raindrop.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hyperColorLink"
                    >
                      {data.FormLink}
                    </a>
                  </p>
                  {/* <p className='SCOBannerpara'>
                {data.paraFour}
                <a href='https://cloud.google.com/security/compliance/soc-2/' className='hyperColorLink'>
                  {' '}
                  {data.GcPlink}
                </a>
              </p> */}
                </div>
              </div>
              <div className="ButtonSection">
                <AppSOCReport />
                <ReUseHTwotag Heading={data.titleTwo} HeadingStyle="GetTryFreeButton" />
                <div className="SingUPButton">
                  <SignUpButton label="Get for free" />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </AppSCOLogoWepper>
  )
}
